import React from 'react';

import { colors } from '../themes/colors';
import { Iconed } from './lib';

export const Beauty: React.FC<Iconed> = ({ width, height, viewBox, color = colors.fuchsia }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width ?? 53}
            height={height ?? 56}
            viewBox={viewBox ?? '0 0 53 56'}>
            <g fill="none" fillRule="evenodd">
                <g fillRule="nonzero">
                    <g>
                        <g>
                            <path
                                fill="#000"
                                d="M52.272 8.937c-1.544-2.668-3.762-4.905-6.415-6.47C43.12.853 39.987 0 36.794 0 33.6 0 30.467.853 27.73 2.467c-2.654 1.565-4.872 3.802-6.416 6.47-.315.544-.29 1.22.062 1.742l8.504 12.553v29.37c0 1.874 1.524 3.398 3.398 3.398h7.03c1.873 0 3.397-1.524 3.397-3.398v-29.37l8.504-12.553c.353-.521.377-1.198.062-1.742zM40.425 52.602c0 .06-.057.117-.117.117H33.28c-.06 0-.117-.057-.117-.117V24.37h7.263v28.233zm.77-31.514H39.95l1.037-3.113c.287-.86-.178-1.789-1.038-2.075-.86-.287-1.788.178-2.075 1.037l-1.08 3.239-1.079-3.239c-.286-.86-1.215-1.324-2.075-1.037-.86.286-1.324 1.215-1.038 2.075l1.038 3.112h-1.247l-7.7-11.365c2.701-3.997 7.25-6.44 12.102-6.44s9.4 2.443 12.1 6.44l-7.699 11.365zM17.463 36.903h-.116v-17.69c0-.905-.735-1.64-1.64-1.64h-.117V3.398c0-1.632-1.165-2.862-2.708-2.862-.39 0-.786.079-1.174.234L6.185 2.98c-1.518.607-2.663 2.297-2.663 3.932v10.661h-.117c-.906 0-1.64.735-1.64 1.64v17.69h-.117c-.906 0-1.64.735-1.64 1.64v14.059C.007 54.476 1.531 56 3.404 56h12.301c1.874 0 3.398-1.524 3.398-3.398V38.544c0-.906-.734-1.64-1.64-1.64zM6.803 6.913c0-.297.326-.777.6-.887l4.905-1.962v13.509H6.803V6.913zM5.046 20.854H14.066v16.05h-9.02v-16.05zm10.777 31.748c0 .06-.057.117-.117.117h-12.3c-.06 0-.117-.057-.117-.117V40.184h12.534v12.418z"
                                transform="translate(-991 -1034) translate(899 293) translate(92 741)"
                            />
                            <path
                                fill={color}
                                d="M15.706 17.573h-.116V3.398c0-1.632-1.165-2.862-2.708-2.862-.39 0-.786.079-1.174.234L6.185 2.98c-1.518.607-2.663 2.297-2.663 3.932v10.661h12.184zM6.803 6.913c0-.297.326-.777.6-.887l4.905-1.962v13.509H6.803V6.913z"
                                transform="translate(-991 -1034) translate(899 293) translate(92 741)"
                            />
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
};
