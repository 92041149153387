import React from 'react';

import { Desktop, Mobile } from '../consts/media';
import { useWindowSize } from '../hooks';

const Layout: React.FC = ({ children }) => {
    const [width] = useWindowSize();
    return (
        <>
            <Mobile>
                <div>{children}</div>
            </Mobile>
            <Desktop>
                <div style={{ margin: `50px ${width / 12}px 0px ${width / 10}px` }}>{children}</div>
            </Desktop>
        </>
    );
};
export default Layout;
