import React from 'react';

import { colors } from '../themes/colors';
import { Iconed } from './lib';

export const Therapy: React.FC<Iconed> = ({ width, height, viewBox, color = colors.fuchsia }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width ?? 47}
            height={height ?? 55}
            viewBox={viewBox ?? '0 0 47 55'}>
            <g fill="none" fillRule="evenodd">
                <g fillRule="nonzero">
                    <g>
                        <g>
                            <path
                                fill="#000"
                                d="M41.514 35.15c-1.414-.817-3.078-1.476-4.958-1.97 4.424-1.688 6.754-3.899 6.754-6.43 0-2.717-2.684-5.066-7.763-6.793-.71-.241-1.455-.465-2.223-.67 1.977-1.417 2.789-3.355 2.789-5.133 0-2.911-2.174-6.25-8.08-7.029.404-.582.64-1.24.64-1.968 0-3.32-4.91-5.157-8.305-5.157-3.66 0-5.607 2.13-5.607 5.157 0 1.429.438 2.651 1.267 3.545-4.217 1.736-5.107 3.92-5.107 5.452 0 1.282.625 3.022 3.303 4.574-1.184.369-2.23.841-3.13 1.418-2.432 1.56-3.772 3.905-3.772 6.604 0 2.699 1.34 5.044 3.772 6.604.402.258.837.493 1.297.71-1.03.288-2.025.604-2.975.947-6.118 2.21-9.35 5.16-9.35 8.534 0 3.372 3.232 6.323 9.35 8.533 5.14 1.857 11.59 2.922 17.7 2.922 5.952 0 10.93-1.058 14.398-3.06 7.285-4.206 7.306-12.572 0-16.79zM20.368 3.116c1.233 0 2.69.342 3.8.892 1.047.519 1.39 1.03 1.39 1.148 0 .118-.343.63-1.39 1.148-1.11.55-2.567.893-3.8.893-2.189 0-2.49-.981-2.49-2.041s.301-2.04 2.49-2.04zm-2.908 8.887c2.232-.907 5.169-1.449 7.856-1.449 4.305 0 7.198 1.447 7.198 3.6 0 2.152-2.893 3.598-7.198 3.598-2.687 0-5.624-.542-7.856-1.449-2.222-.902-2.94-1.838-2.94-2.15 0-.311.718-1.247 2.94-2.15zM10.92 26.75c0-3.43 3.936-5.398 10.797-5.398 4.23 0 8.966.752 12.672 2.012 3.877 1.319 5.322 2.777 5.322 3.386 0 .61-1.445 2.067-5.322 3.386-3.706 1.26-8.443 2.012-12.672 2.012-6.861 0-10.796-1.967-10.796-5.398zm28.735 21.971c-2.902 1.676-7.239 2.562-12.54 2.562-5.611 0-11.756-1.01-16.436-2.7-5.09-1.84-6.897-3.864-6.897-5.038 0-1.175 1.806-3.2 6.897-5.039 4.68-1.69 10.825-2.7 16.437-2.7 5.3 0 9.637.886 12.54 2.562 4.813 2.78 4.816 7.572 0 10.353z"
                                transform="translate(-1545 -644) translate(899 125) translate(646 519)"
                            />
                            <path
                                fill={color}
                                d="M39.062 42.203c-.714-.684-3.684-2.916-11.946-2.916-1.027 0-1.859.832-1.859 1.858 0 1.027.832 1.859 1.859 1.859 6.079 0 8.827 1.359 9.373 1.882.741.71 1.917.686 2.628-.055.71-.741.686-1.917-.055-2.628z"
                                transform="translate(-1545 -644) translate(899 125) translate(646 519)"
                            />
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
};
