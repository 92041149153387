/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */

import styled from '@emotion/styled';
import React from 'react';
import { useHistory } from 'react-router-dom';

import logo from '../assets/img/logo.png';
import { Desktop, Mobile } from '../consts/media';
import { useUIStore } from '../contexts/UIContext';
import { useUser } from '../hooks';
import { useWindowSize } from '../hooks';
import { MainRoute, Routes } from '../routes';
import { colors } from '../themes/colors';
import { AvailableSidebars } from '../types/global';
import { PrimaryButton } from './Button';
import { FlexRow } from './FlexContainers';
import Menu from './Menu';

const Wrapper = styled.header`
    min-height: 100px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: ${colors.almostBlack};
    transition: all 0.7s ease-in;
`;
const Mobiled = styled.header`
    min-width: 360px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 20px;
    padding-right: 20px;
    min-height: 60px;
    box-sizing: border-box;
    padding: 15px;
    background-color: ${colors.almostBlack};
`;
const tabs = Object.values(Routes)
    .filter((route) => route.menu)
    .map((route) => ({ label: route.label, link: route.link }));

const Navbar = () => {
    const history = useHistory();
    const uiStore = useUIStore();
    const user = useUser();

    const [width] = useWindowSize();
    const onStartClick = () => {
        if (user) {
            uiStore.setSidebar(AvailableSidebars.ApplyForm);
        } else {
            history.push(Routes.authentication.link);
        }
    };

    return (
        <>
            <Mobile>
                <Mobiled>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            marginBottom: '12px'
                        }}>
                        <img
                            src={logo}
                            alt="logo"
                            onClick={() =>
                                history.push({
                                    pathname: MainRoute.link
                                })
                            }
                            width="100px"
                            style={{ cursor: 'pointer' }}
                        />
                        <PrimaryButton style={{ width: 70, fontSize: 10 }} onClick={onStartClick}>
                            Apply as TopiPro
                        </PrimaryButton>
                    </div>
                    <Menu
                        tabs={tabs}
                        style={{
                            fontSize: '14px'
                        }}
                    />
                </Mobiled>
            </Mobile>
            <Desktop>
                <Wrapper>
                    <FlexRow
                        className="window-nav"
                        style={{
                            margin: `12px ${width / 12}px 0px ${width / 9}px`,
                            width: '100%'
                        }}>
                        <img
                            src={logo}
                            alt="logo"
                            onClick={() =>
                                history.push({
                                    pathname: MainRoute.link
                                })
                            }
                            style={{ cursor: 'pointer' }}
                        />
                        <Menu style={{ marginLeft: 'auto' }} tabs={tabs} />
                    </FlexRow>
                </Wrapper>
            </Desktop>
        </>
    );
};
export default Navbar;
