import React from 'react';

import { colors } from '../themes/colors';
import { Iconed } from './lib';

export const Design: React.FC<Iconed> = ({ width, height, viewBox, color = colors.fuchsia }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width ?? 52}
            height={height ?? 48}
            viewBox={viewBox ?? '0 0 52 48'}>
            <g fill="none" fillRule="evenodd">
                <g fillRule="nonzero">
                    <g>
                        <g>
                            <g fill="#000">
                                <path
                                    d="M47.034 23.677c-.418-7.427-4.6-14.113-11.05-17.818h5.479c.605 1.705 2.233 2.93 4.142 2.93C48.03 8.79 50 6.818 50 4.395 50 1.97 48.029 0 45.605 0c-1.91 0-3.537 1.225-4.142 2.93H29.395V0h-8.79v2.93H8.537C7.932 1.225 6.304 0 4.395 0 1.97 0 0 1.971 0 4.395c0 2.423 1.971 4.394 4.395 4.394 1.91 0 3.537-1.225 4.142-2.93h5.48C7.569 9.561 3.384 16.245 2.966 23.676 1.242 24.272 0 25.91 0 27.832c0 2.423 1.971 4.395 4.395 4.395 2.423 0 4.394-1.972 4.394-4.395 0-1.895-1.206-3.514-2.89-4.13.533-8.326 6.578-15.416 14.706-17.333v2.42h8.79V6.37c8.128 1.916 14.173 9.007 14.706 17.334-1.684.615-2.89 2.234-2.89 4.129 0 2.423 1.971 4.395 4.394 4.395 2.424 0 4.395-1.972 4.395-4.395 0-1.923-1.242-3.56-2.966-4.155zm-42.64 5.62c-.807 0-1.464-.657-1.464-1.465s.657-1.465 1.465-1.465c.807 0 1.464.657 1.464 1.465s-.657 1.465-1.464 1.465zM45.606 2.93c.808 0 1.465.657 1.465 1.465 0 .807-.657 1.464-1.465 1.464-.807 0-1.464-.657-1.464-1.464 0-.808.657-1.465 1.464-1.465zM4.395 5.86c-.808 0-1.465-.658-1.465-1.465 0-.808.657-1.465 1.465-1.465.807 0 1.464.657 1.464 1.465 0 .807-.657 1.464-1.464 1.464zm22.07 0h-2.93V2.93h2.93v2.93zm19.14 23.437c-.807 0-1.464-.657-1.464-1.465s.657-1.465 1.464-1.465c.808 0 1.465.657 1.465 1.465s-.657 1.465-1.465 1.465z"
                                    transform="translate(-1536 -189) translate(899 125) translate(638 65)"
                                />
                                <path
                                    d="M25 10.336L14.541 27.768l5.16 10.318h-4.955v8.789h20.508v-8.79H30.3l5.159-10.317L25 10.336zm7.324 33.61H17.676v-2.93h14.648v2.93zm-9.348-5.86l-5.095-10.19 5.654-9.424v10.825h2.93V18.472l5.654 9.424-5.095 10.19h-4.048z"
                                    transform="translate(-1536 -189) translate(899 125) translate(638 65)"
                                />
                            </g>
                            <path
                                stroke={color}
                                strokeWidth="3"
                                d="M4.395 30.832c-1.655 0-3-1.346-3-3s1.345-3 3-3c1.654 0 3 1.346 3 3s-1.346 3-3 3zM27.965 1.43v5.93h-5.93V1.43h5.93zM45.605 24.867c.818 0 1.56.332 2.096.87.537.536.87 1.278.87 2.095 0 .817-.333 1.559-.87 2.096-.537.537-1.278.869-2.096.869-.817 0-1.559-.332-2.096-.869-.536-.537-.868-1.279-.868-2.096 0-.817.332-1.559.868-2.096.537-.537 1.28-.869 2.096-.869z"
                                transform="translate(-1536 -189) translate(899 125) translate(638 65)"
                            />
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
};
