import React from 'react';

import { LargeText, WelcomeText } from '../../../components';
import { Desktop, Mobile } from '../../../consts/media';

const Header: React.FC = () => {
    return (
        <>
            <Mobile>
                <WelcomeText style={{ fontSize: 20 }}>Welcome to</WelcomeText>
                <LargeText style={{ fontSize: 40 }}>
                    Top<span className="i">i</span>Pro
                </LargeText>
            </Mobile>
            <Desktop>
                <WelcomeText>Welcome to</WelcomeText>
                <LargeText>
                    Top<span className="i">i</span>Pro
                </LargeText>
            </Desktop>
        </>
    );
};
export default Header;
