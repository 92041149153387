import React from 'react';

import { colors } from '../themes/colors';
import { Iconed } from './lib';

export const Marketing: React.FC<Iconed> = ({ width, height, viewBox, color = colors.fuchsia }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width ?? 50}
            height={height ?? 56}
            viewBox={viewBox ?? '0 0 56 56'}>
            <g fill="none" fillRule="evenodd">
                <g fillRule="nonzero">
                    <g>
                        <g>
                            <path
                                fill="#000"
                                d="M46.53 22.028L27.558 3.441c-1.962-1.921-5.153-1.921-7.114 0-1.763 1.728-1.915 4.362-.613 6.243l-.027.13c-1.367 6.7-4.697 12.797-9.63 17.629l-8.7 8.525c-1.966 1.927-1.967 5.044 0 6.97l4.742 4.647c1.966 1.926 5.148 1.926 7.114 0l1.186-1.162 8.3 8.132c1.965 1.927 5.147 1.927 7.113 0 1.961-1.921 1.961-5.048 0-6.97L26.373 44.1l1.186-1.162c1.966-1.926 1.966-5.043 0-6.97l-.806-.79c3.964-2.718 8.465-4.611 13.272-5.553l.134-.026c1.963 1.298 4.645 1.09 6.37-.6 1.961-1.922 1.961-5.049 0-6.97zM10.96 45.262c-.656.642-1.716.642-2.372 0l-4.742-4.647c-.656-.642-.656-1.681 0-2.323l8.3-8.132 7.113 6.97-8.3 8.132zm16.599 4.646c.653.64.653 1.683 0 2.324-.654.64-1.718.64-2.372 0l-8.3-8.132 2.372-2.323 8.3 8.131zM21.63 39.453c.504-.494 1.259-1.25 2.45-2.247l1.107 1.086c.656.642.656 1.68 0 2.323l-1.185 1.162-2.372-2.324zm.072-4.576l-7.257-7.11c3.889-4.4 6.674-9.593 8.164-15.236L37.25 26.878c-5.758 1.46-11.059 4.188-15.55 7.999zm22.456-8.202c-.656.642-1.716.642-2.372 0L22.816 8.088c-.655-.642-.655-1.681 0-2.323.655-.643 1.716-.643 2.371 0l18.97 18.586c.654.641.654 1.683 0 2.324z"
                                transform="translate(-1262 -185) translate(899 125) translate(363 60)"
                            />
                            <path
                                fill={color}
                                d="M13.485 35.515c-.686-.687-1.799-.687-2.485 0L8.515 38c-.687.686-.687 1.799 0 2.485.686.687 1.799.687 2.485 0L13.485 38c.687-.686.687-1.799 0-2.485zM35.5 0c-.828 0-1.5.784-1.5 1.75v3.5c0 .966.672 1.75 1.5 1.75S37 6.216 37 5.25v-3.5C37 .784 36.328 0 35.5 0zM48.25 13h-3.5c-.966 0-1.75.672-1.75 1.5s.784 1.5 1.75 1.5h3.5c.966 0 1.75-.672 1.75-1.5s-.784-1.5-1.75-1.5zM46.487 3.513c-.683-.684-1.791-.684-2.474 0l-3.5 3.5c-.684.683-.684 1.791 0 2.474.683.684 1.791.684 2.474 0l3.5-3.5c.684-.683.684-1.791 0-2.474z"
                                transform="translate(-1262 -185) translate(899 125) translate(363 60)"
                            />
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
};
