import { useMediaQuery } from 'react-responsive';

export const Desktop = ({ children }) => {
    const isTablet = useMediaQuery({ minWidth: 1001 });
    return isTablet ? children : null;
};

export const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: 1000 });
    return isMobile ? children : null;
};
