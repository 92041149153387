import React from 'react';

import {
    Badge,
    CardBox,
    FlexBox,
    KeyValueColumn,
    Label,
    Picture,
    ProHeader
} from '../../../components';
import { Desktop, Mobile } from '../../../consts/media';
import { BaseIPro } from '../../../types';
import { roundDecimal } from '../../../utils/number';

const IPro: React.FC<BaseIPro & { onClick: () => void }> = ({
    name,
    type,
    spoq,
    reviews,
    score,
    image,
    onClick
}) => {
    return (
        <>
            <Mobile>
                <CardBox onClick={onClick}>
                    <Picture src={image} />
                    <ProHeader style={{ textAlign: 'center' }}>{name}</ProHeader>
                    <Label style={{ textAlign: 'center' }}>{type}</Label>
                    <FlexBox style={{ justifyContent: 'space-evenly', marginTop: 10 }}>
                        <KeyValueColumn label={'SPOQ'} value={spoq} />
                        <KeyValueColumn label={'Reviews'} value={reviews} />
                        <KeyValueColumn
                            label={'Score'}
                            value={<Badge value={roundDecimal(score)} />}
                        />
                    </FlexBox>
                </CardBox>
            </Mobile>
            <Desktop>
                <CardBox onClick={onClick}>
                    <Picture src={image} />
                    <ProHeader style={{ textAlign: 'center' }}>{name}</ProHeader>
                    <Label style={{ textAlign: 'center' }}>{type}</Label>
                    <FlexBox
                        style={{ justifyContent: 'space-evenly', marginTop: 20, marginBottom: 10 }}>
                        <KeyValueColumn label={'SPOQ'} value={spoq} />
                        <KeyValueColumn label={'Reviews'} value={reviews} />
                        <KeyValueColumn
                            label={'Score'}
                            value={<Badge value={roundDecimal(score)} />}
                        />
                    </FlexBox>
                </CardBox>
            </Desktop>
        </>
    );
};
export default IPro;
