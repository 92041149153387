import styled from '@emotion/styled';
import React from 'react';

import { CardBox, FlexColumn, Label, PrimaryButton, ProHeader } from '../../components';
import { Desktop, Mobile } from '../../consts/media';

const Wrapper = styled(FlexColumn)`
    padding: 25px 9.2px 27px 20px;
`;

function StringCut(str) {
    return str.length > 150 ? str.substring(0, 150) + '...' : str;
}
interface IBlogCard {
    name: string;
    summary: string;
    onClick: () => void;
    style?: React.CSSProperties;
}

const BlogCard: React.FC<IBlogCard> = ({ name, summary, onClick, style }) => {
    return (
        <>
            <Mobile>
                <CardBox onClick={onClick} style={style}>
                    <Wrapper>
                        <ProHeader style={{ fontSize: '15px' }}>{name}</ProHeader>
                        <Label style={{ fontSize: '15px', marginTop: 5, height: 100 }}>
                            {StringCut(summary)}
                        </Label>
                        <div style={{ margin: 'auto' }}>
                            <PrimaryButton onClick={onClick} style={{ fontSize: '12px' }}>
                                Read more
                            </PrimaryButton>
                        </div>
                    </Wrapper>
                </CardBox>
            </Mobile>
            <Desktop>
                <CardBox onClick={onClick} style={style}>
                    <Wrapper>
                        <ProHeader>{name}</ProHeader>
                        <Label style={{ fontSize: '0.8em', marginTop: 5, height: 180 }}>
                            {summary}
                        </Label>
                        <div style={{ margin: 'auto' }}>
                            <PrimaryButton onClick={onClick} style={{ fontSize: '0.8em' }}>
                                Read more
                            </PrimaryButton>
                        </div>
                    </Wrapper>
                </CardBox>
            </Desktop>
        </>
    );
};
export default BlogCard;
