import React from 'react';

import { Label } from '../../../components';
import { Desktop, Mobile } from '../../../consts/media';
import { IReview } from '../../../containers/ApplyForm/types';

const Review: React.FC<IReview> = ({ description }) => {
    return (
        <div>
            <Mobile>
                <div style={{ width: 200 }}>
                    <Label style={{ fontSize: '15px', marginTop: 5 }}>{description}</Label>
                    <br />
                </div>
            </Mobile>
            <Desktop>
                <Label style={{ fontSize: '0.9em', marginTop: 10 }}>{description}</Label>
                <br />
            </Desktop>
        </div>
    );
};
export default Review;
