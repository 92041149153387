import React from 'react';
import { SVGUniqueID } from 'react-svg-unique-id';

import { colors } from '../themes/colors';
import { Iconed } from './lib';

export const Share: React.FC<Iconed> = ({ color = colors.fuchsia, style }) => {
    return (
        <SVGUniqueID>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="22"
                viewBox="0 0 20 22"
                style={style}>
                <g fill="none" fillRule="evenodd">
                    <g fill={color} fillRule="nonzero">
                        <g>
                            <path
                                d="M15.327 16.576c-1.213 0-2.299.558-3.02 1.434l-4.685-2.686c.146-.415.226-.86.226-1.324 0-.464-.08-.909-.226-1.323l4.686-2.687c.72.876 1.806 1.435 3.019 1.435 2.163 0 3.923-1.778 3.923-3.963S17.49 3.5 15.327 3.5c-2.164 0-3.924 1.777-3.924 3.962 0 .464.08.909.226 1.323l-4.686 2.687c-.72-.875-1.806-1.434-3.019-1.434C1.76 10.038 0 11.816 0 14c0 2.185 1.76 3.962 3.924 3.962 1.213 0 2.298-.558 3.019-1.434l4.686 2.687c-.146.414-.226.86-.226 1.323 0 2.185 1.76 3.962 3.924 3.962 2.163 0 3.923-1.777 3.923-3.962 0-2.185-1.76-3.962-3.923-3.962zm0-11.69c1.406 0 2.55 1.156 2.55 2.576 0 1.42-1.144 2.576-2.55 2.576-1.407 0-2.551-1.155-2.551-2.576 0-1.42 1.144-2.576 2.55-2.576zM3.924 16.576c-1.406 0-2.55-1.156-2.55-2.576 0-1.42 1.144-2.575 2.55-2.575 1.407 0 2.55 1.155 2.55 2.575 0 1.42-1.143 2.576-2.55 2.576zm11.403 6.538c-1.407 0-2.551-1.156-2.551-2.576 0-1.42 1.144-2.576 2.55-2.576 1.407 0 2.551 1.156 2.551 2.576 0 1.42-1.144 2.576-2.55 2.576z"
                                transform="translate(-820 -265) translate(820 262)"
                            />
                        </g>
                    </g>
                </g>
            </svg>
        </SVGUniqueID>
    );
};
